import React, { PureComponent } from "react";
import { withTheme } from "styled-components";
import styled from "styled-components";
import Image from "next/image";
import CustomFont from "../../components/CustomFont";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {Color} from "../../src/utils/Color";
import Container from "@material-ui/core/Container"
const MainContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 75vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  height: 200px;
  width: 200px;
  object-fit: contain;
`;

class EmptyItemBox extends PureComponent {
  onPressButton = () => {
    const { onPress } = this.props;

    onPress && onPress();
  };

  render() {
    const { image, title, desc, buttonText, buttonStyle } = this.props;
    return (
      <Container>
        <MainContainer>
          <ImageContainer>
            <Image src="/not-found.png" height={200} width={200} alt="not-found.png" />
          </ImageContainer>
          <CustomFont
            size="large"
            styles={{ fontWeight: "600", marginTop: "1rem" }}
          >
            {title}
          </CustomFont>
          {!_.isEmpty(desc) ? (
            <CustomFont styles={{ marginTop: "1rem",textAlign:"center" }}>{desc}</CustomFont>
          ) : (
            false
          )}
          {!_.isEmpty(buttonText) ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: Color.primaryColor,
                color: "white",
                marginTop: "1.5rem",
                ...buttonStyle
              }}
              onClick={this.onPressButton}
            >
              {buttonText}
            </Button>
          ) : (
            false
          )}
        </MainContainer>
      </Container>
    );
  }
}

export default withTheme(EmptyItemBox);
